import React, { useEffect, Fragment } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Badge } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatNumber } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { obtenerEmpresa, limpiarEmpresa, detallesArchivo, limpiarArchivo } from '../../actions/empresas';

import NuevoArchivo from './NuevoArchivo';
import EditarArchivo from './EditarArchivo';
import EliminarArchivo from './EliminarArchivo';

const PerfilEmpresa = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Empresas',
            path: '/empresas'
        },
        {
            activo: true,
            nombre: 'Perfil de empresa',
            path: '/empresas/perfil/'
        }
    ];

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles, detalles_archivo } = useSelector(state => state.empresas);

    const [empresaData, setEmpresaData] = useState({
        indicadores_generales: {
            ingresos: 0,
            egresos: 0,
            utilidad: 0
        },
        informacion_general: {
            id: null,
            nombre: null,
            categoria_empresa: null,
            director: null,
            direccion: null,
            colaboradores: [],
            archivos: [], 
            url_perfil: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
        },
        informacion_fiscal: {
            rfc: null, 
            razon_social: null, 
            representante_legal: null, 
            regimen_fiscal: null, 
            direccion_fiscal: null, 
            correo_fiscal: null, 
            telefono_fiscal: null, 
            fecha_cif: null
        },
        informacion_bancaria: {
            cuentas: []
        }
    });

    /* Archivos */
    const [nuevoArchivo, setNuevoArchivo] = useState({
        empresa: null
    });
    const [editarArchivo, setEditarArchivo] = useState(null);
    const [eliminarArchivo, setEliminarArchivo] = useState(null);

    const cuentasBancariasColumnas = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Banco',
            selector: row => row.banco,
        },
        {
            name: 'Número de cuenta',
            selector: row => row.numero,
        },
        {
            name: 'CLABE',
            selector: row => row.clabe,
        }
    ];

    const colaboradoresColumnas = [
        {
            name: 'Nombre',
            grow: 2, 
            selector: row => row.nombre ? [row.nombre, row.apellido_paterno, row.apellido_materno].join(' ') : 'Sin definir',
        },
        {
            name: 'Antigüedad',
            grow: 2, 
            selector: row => row.fecha_inicio ? convertFechaTiempo(row.fecha_inicio) : 'Sin definir',
        },
        {
            name: 'Puesto',
            grow: 2, 
            selector: row => row.puesto,
        },
        {
            name: 'Status',
            selector: row => {
                switch(row.status){
                    case false:
                        return(
                            <h5><Badge pill bg="danger">Inactivo</Badge></h5>
                        )
                    case true:
                        return(
                            <h5><Badge pill bg="success">Activo</Badge></h5>
                        )
                    default:
                        break;
                }
            }
        }, 
        {
            name: '',
            grow: 1, 
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <Link className="btn btn-md btn-link px-2 float-end" to={`/colaboradores/perfil/${row._id}`}><i className="fas fa-circle-info text-info"></i></Link>
                    </div>
                )
            }
        }
    ];

    const archivosColumnas = [
        {
            name: 'Nombre',
            grow: 2, 
            selector: row => row.nombre || 'Sin definir'
        },
        {
            name: 'Descripción',
            grow: 2, 
            selector: row => row.descripcion || 'Sin definir'
        },
        {
            name: 'Tipo',
            selector: row => row.extension ? row.extension.toUpperCase() : 'Sin definir'
        },
        {
            name: 'Fecha de carga',
            cell: item => item.fecha ? new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'
        },
        {
            name: '',
            grow: 1, 
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarArchivo({ id_empresa: id, id_archivo: row._id, nombre: row.nombre })}><i className="fas fa-times text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarArchivo({ id_empresa: id, id_archivo: row._id, ...row })}><i className="fas fa-edit text-primary"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDetallesArchivo({ id_empresa: id, id_archivo: row._id })}><i className="fas fa-eye text-info"></i></button>
                    </div>
                )
            }
        }
    ];

    const {indicadores_generales, informacion_general, informacion_fiscal, informacion_bancaria} = empresaData;

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    /* Archivos */
    const handleAgregarArchivo = (empresa) => {
        setNuevoArchivo({
            empresa
        });
    }

    const handleDetallesArchivo = (archivo) => {
        dispatch(detallesArchivo(archivo));
    }

    const handleAbrirArchivo = () => {
        if(!editarArchivo){
            window.open(detalles_archivo.url);
        }
    }

    const handleEditarArchivo = (empresa) => {
        setEditarArchivo(empresa);
    }

    const handleEliminarArchivo = (empresa) => {
        setEliminarArchivo(empresa);
    }

    /* Funciones generales */
    const convertFechaTiempo = (fecha) => {
        let mensaje = 'Sin definir';

        if(fecha){
            let diferencia = Math.floor(new Date().getTime() - new Date(fecha).getTime());
            let cantidad_dia = 1000 * 60 * 60 * 24;

            let dias = Math.floor(diferencia / cantidad_dia);
            let meses = 0;
            let anos = 0;

            if(dias >= 365){
                anos = Math.floor(dias / 365);
                dias = dias - (anos * 365);
            }

            if(dias >= 30){
                meses = Math.floor(dias / 30);
                dias = dias - (meses * 30);
            }

            let texto_dias = dias + ' ' + (dias == 1 ? 'dia' : 'dias');
            let texto_meses = meses + ' ' + (meses == 1 ? 'mes' : 'meses');
            let texto_anos = anos + ' ' + (anos == 1 ? 'año' : 'años');

            mensaje = texto_anos + ' ' + texto_meses + ' ' + texto_dias;
        }

        return mensaje;
    }

    useEffect(() => {
        dispatch(limpiarEmpresa());
        dispatch(limpiarArchivo());

        return () => {
            dispatch(limpiarEmpresa());
            dispatch(limpiarArchivo());
        }
    }, []);

    useEffect(() => {        
        if(id){
            dispatch(obtenerEmpresa(id));

            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    useEffect(() => {
        if(detalles){
            setEmpresaData({
                ...empresaData,
                indicadores_generales: detalles.indicadores_generales, 
                informacion_general: {
                    ...detalles.informacion_general,
                    url_perfil: detalles.informacion_general.url_perfil || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
                }, 
                informacion_fiscal: {
                    ...detalles.informacion_fiscal
                }, 
                informacion_bancaria: {
                    ...detalles.informacion_bancaria
                }
            });
        }
    }, [detalles]);

    useEffect(() => {        
        if(detalles_archivo && detalles_archivo?.extension && detalles_archivo?.url){
            handleAbrirArchivo();
        }
    }, [detalles_archivo]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .contenedor_imagen_perfil {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        #imagen_perfil {
                            width: auto;
                            height: 100%;
                            display: block;
                            position: absolute;
                            margin: auto;
                            top: -100%;
                            right: -100%;
                            bottom: -100%;
                            left: -100%;
                        }

                        .btn-badge-status{
                            background-color: #fff;
                            border: none;
                            outline: none;
                            padding: 0;
                        }

                        .valor_indicador {
                            font-size: 40px;
                            margin: 0;
                        }

                        .descripcion_indicador {
                            margin: 0;
                        }

                        .text-wrap {
                            white-space: normal;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <NuevoArchivo archivo={nuevoArchivo} reestablecerArchivo={handleAgregarArchivo} />
            <EditarArchivo archivo={editarArchivo} reestablecerArchivo={handleEditarArchivo} />
            <EliminarArchivo archivo={eliminarArchivo} reestablecerArchivo={handleEliminarArchivo} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Perfil de empresa</h3>
                    
                    <div className="row mb-4">
                        <div className="col-md-3 text-center">
                            <p className="valor_indicador">{ formatNumber(informacion_general?.colaboradores.filter( colaborador => colaborador.status ).length, 0, '', '') }</p>
                            <p className="descripcion_indicador">Colaboradores</p>
                        </div>
                        <div className="col-md-3 text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.ingresos, 2, '$', '') }</p>
                            <p className="descripcion_indicador">Ingresos totales</p>
                        </div>
                        <div className="col-md-3 text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.egresos, 2, '$', '') }</p>
                            <p className="descripcion_indicador">Egresos totales</p>
                        </div>
                        <div className="col-md-3 text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.utilidad, 2, '', ' %') }</p>
                            <p className="descripcion_indicador">Utilidad total</p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-3 py-3 text-center">
                            <label className="contenedor_imagen_perfil">
                                <div className="contenedor_imagen">
                                    <img id="imagen_perfil" src={ informacion_general?.url_perfil }/>
                                </div>
                            </label>
                            <div className="mt-2 d-flex justify-content-center">
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="accordion" id="accordionDetalles">
                                
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Información general
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-3">
                                                <label className="fw-bold">Nombre</label>
                                                <p>{informacion_general?.nombre}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Categoria</label>
                                                <p>{informacion_general?.categoria_empresa || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Director</label>
                                                <p>{informacion_general?.director || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Fecha de creación</label>
                                                <p>{new Date(informacion_general?.fecha_creacion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="fw-bold">Dirección</label>
                                                <p>{informacion_general?.direccion}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            Información fiscal
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-3">
                                                <label className="fw-bold">RFC</label>
                                                <p>{informacion_fiscal?.rfc || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Razón social</label>
                                                <p>{informacion_fiscal?.razon_social || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Representante legal</label>
                                                <p>{informacion_fiscal?.representante_legal || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Régimen fiscal</label>
                                                <p>{informacion_fiscal?.regimen_fiscal || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Correo</label>
                                                <p>{informacion_fiscal?.correo_fiscal || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Teléfono</label>
                                                <p>{informacion_fiscal?.correo_fiscal || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="fw-bold">Dirección</label>
                                                <p>{informacion_fiscal?.direccion_fiscal || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Fecha de CIF</label>
                                                <p>{informacion_fiscal?.fecha_cif ? new Date(informacion_fiscal?.fecha_cif).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                            Información bancaria
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                        <DataTable
                                                columns={cuentasBancariasColumnas}
                                                data={informacion_bancaria.cuentas_bancarias}
                                                pagination
                                                paginationPerPage={3}
                                                paginationRowsPerPageOptions={[3, 10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                            Colaboradores
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <DataTable
                                                columns={colaboradoresColumnas}
                                                data={informacion_general.colaboradores}
                                                pagination
                                                paginationPerPage={3}
                                                paginationRowsPerPageOptions={[3, 10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                            Archivos
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-12 text-end mb-3">
                                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                    <button type="button" className="btn btn-primary" onClick={() => handleAgregarArchivo(id)}><i className="fa-solid fa-plus"></i> Archivo</button>
                                                </div>
                                            </div>
                                            <DataTable
                                                columns={archivosColumnas}
                                                data={informacion_general.archivos}
                                                pagination
                                                paginationPerPage={3}
                                                paginationRowsPerPageOptions={[3, 10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PerfilEmpresa;