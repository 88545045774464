import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { editarBreadcrumb } from '../../actions/breadcrumb';

const Dashboard = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Inicio',
            path: '/dashboard'
        }
    ];

    const dispatch = useDispatch();

    const usuario = useSelector(state => state.auth.usuario);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <div className="row">
            <div className="col-md-4 offset-md-4 p-5 text-center">
                <h1 className="my-4">Sección en desarrollo</h1>
            </div>
            <div className="col-md-2 offset-md-5 p-5 text-center">
                <img className="w-100" src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo.png'} alt="logo" />
            </div>
        </div>
    )
}

export default Dashboard;