import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment-timezone';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarEventos, informacionEvento } from '../../actions/calendario';

import { messages } from '../../utils/calendar-messages-español';
import { CalendarEvent } from '../../components/big_calendar/CalendarEvent';

import NuevaVacacion from './NuevaVacacion';
import NuevaIncidencia from './NuevaIncidencia';
import NuevoRemoto from './NuevoRemoto';

moment.locale('es');
moment.tz.setDefault('America/Mexico_City');    

const Calendario = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Calendario',
            path: '/calendario'
        }
    ];

    const dispatch = useDispatch();

    const localizer = momentLocalizer(moment);

    const { usuario } = useSelector(state => state.auth);
    const { listado } = useSelector(state => state.calendario);

    const [eventos, setEventos] = useState([]);
    const [lastView, setLastView] = useState(localStorage.getItem('lastView') || 'month');

    /* Vacaciones */
    const [nuevaVacacion, setNuevaVacacion] = useState({
        colaborador: null
    });
    const [editarVacacion, setEditarVacacion] = useState(null);
    const [statusVacacion, setStatusVacacion] = useState(null);    
    const [eliminarVacacion, setEliminarVacacion] = useState(null);

    /* Incidencias */
    const [nuevaIncidencia, setNuevaIncidencia] = useState({
        colaborador: null
    });
    const [editarIncidencia, setEditarIncidencia] = useState(null);
    const [statusIncidencia, setStatusIncidencia] = useState(null);    
    const [eliminarIncidencia, setEliminarIncidencia] = useState(null);

    /* Remotos */
    const [nuevoRemoto, setNuevoRemoto] = useState({
        colaborador: null
    });
    const [editarRemoto, setEditarRemoto] = useState(null);
    const [statusRemoto, setStatusRemoto] = useState(null);    
    const [eliminarRemoto, setEliminarRemoto] = useState(null);

    /* Vacaciones */

    const handleAgregarVacacion = (colaborador) => {
        setNuevaVacacion({
            colaborador: colaborador
        });
    }

    /* const handleEditarVacacion = (vacacion) => {
        setEditarVacacion(vacacion);
    }

    const handleStatusVacacion = (vacacion) => {
        setStatusVacacion(vacacion);
    }

    const handleEliminarVacacion = (vacacion) => {
        setEliminarVacacion(vacacion);
    } */

    /* Incidencia */

    const handleAgregarIncidencia = (colaborador) => {
        setNuevaIncidencia({
            colaborador: colaborador
        });
    }

    /* const handleEditarIncidencia = (incidencia) => {
        setEditarIncidencia(incidencia);
    }

    const handleStatusIncidencia = (incidencia) => {
        setStatusIncidencia(incidencia);
    }

    const handleEliminarIncidencia = (incidencia) => {
        setEliminarIncidencia(incidencia);
    } */

    /* Remoto */

    const handleAgregarRemoto = (colaborador) => {
        setNuevoRemoto({
            colaborador: colaborador
        });
    }

    /* const handleEditarRemoto = (remoto) => {
        setEditarRemoto(remoto);
    }

    const handleStatusRemoto = (remoto) => {
        setStatusRemoto(remoto);
    }

    const handleEliminarRemoto = (remoto) => {
        setEliminarRemoto(remoto);
    } */

    /* Funciones generales */
    const onRangeChange = (date) => {
        let tipo_vista = localStorage.getItem('lastView') || 'month';
        let primer_dia = null;
        let ultimo_dia = null;

        switch(tipo_vista){
            case 'month':
            case 'agenda':
                primer_dia = new Date(date.start);
                ultimo_dia = new Date(date.end);
                break;

            case 'week':
                primer_dia = new Date(date[0]);
                ultimo_dia = new Date(date[6]);
                break;

            case 'day':
                primer_dia = new Date(date[0]);
                ultimo_dia = new Date(date[0]);
                break;
        }

        dispatch(buscarEventos(primer_dia, ultimo_dia));
    }

    const onViewChange = (view) => {
        setLastView(view);
        localStorage.setItem('lastView', view);
    }

    const eventStyleGetter = (event, start, end, isSelected) =>{
        const style = {
            backgroundColor: event.bgColor,
            borderRadius: '0px',
            opacity: 0.8,
            display: 'block',
            color: event.txColor
        }

        return  {
            style
        }
    }

    const onSelectEvent = (event) => {
        if(event.tipo == 1){
            /* handleDetallesBloqueo({ 
                id: event.id, 
                joven: event.joven, 
                asesor: event.asesor
            }); */
        }else{
            /* handleDetallesCita({ 
                id: event.id, 
                joven: event.joven, 
                asesor: event.asesor
            }); */
        }
    }

    useEffect(() => {
        let tipo_vista = localStorage.getItem('lastView') || 'month';
        let dia_actual = new Date();
        let primer_dia = null;
        let ultimo_dia = null;

        switch(tipo_vista){
            case 'month':
                primer_dia = new Date(dia_actual.getFullYear(), dia_actual.getMonth(), 1);
                ultimo_dia = new Date(dia_actual.getFullYear(), dia_actual.getMonth() + 1, 0);

                let dias_totales = new Date(dia_actual.getFullYear(), dia_actual.getMonth() + 1, 0).getDate();
                let dias_antes = primer_dia.getDay();
                let dias_despues = 35 - dias_totales - dias_antes;

                primer_dia.setDate(primer_dia.getDate() - dias_antes);
                ultimo_dia.setDate(ultimo_dia.getDate() + dias_despues);
                break;

            case 'week':
                let primero = dia_actual.getDate() - dia_actual.getDay();
                let ultimo = primero + 6;

                primer_dia = new Date(dia_actual.setDate(primero));
                ultimo_dia = new Date(dia_actual.setDate(ultimo));
                break;

            case 'day':
                primer_dia = dia_actual;
                ultimo_dia = dia_actual;
                break;

            case 'agenda':
                primer_dia = new Date();
                ultimo_dia = new Date();
                ultimo_dia.setDate(ultimo_dia.getDate() + 30);
                break;
        }

        dispatch(buscarEventos(primer_dia, ultimo_dia));

        return () => {
            dispatch(informacionEvento(null));
        }
    }, []);

    useEffect(() => {
        let listado_eventos = [];

        for (var i = 0; i < listado?.length; i++) {
            listado_eventos.push({
                start: moment(listado[i]?.fecha_inicio).toDate(),
                end: moment(listado[i]?.fecha_fin).toDate(),
                title: listado[i]?.titulo,
                tipo: listado[i]?.tipo,
                allDay: listado[i]?.allDay,
                joven: listado[i]?.joven,
                asesor: listado[i]?.asesor,
                bgColor: listado[i]?.bgColor,
                txColor: listado[i]?.txColor
            });
        }

        setEventos(listado_eventos);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>
            <NuevaVacacion vacacion={nuevaVacacion} reestablecerVacacion={handleAgregarVacacion} />
            <NuevaIncidencia incidencia={nuevaIncidencia} reestablecerIncidencia={handleAgregarIncidencia} />
            <NuevoRemoto remoto={nuevoRemoto} reestablecerRemoto={handleAgregarRemoto} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Calendario general</h3>

                    <div className="d-flex justify-content-end mb-4">
                        <div className="btn-group dropleft" role="group">
                            <button type="button" className="btn dropdown-toggle btn-primary" data-bs-toggle="dropdown" aria-expanded="false">
                                Agregar evento
                            </button>
                            <ul class="dropdown-menu">
                                <li><button type="button" class="dropdown-item" onClick={() => handleAgregarVacacion(usuario?._id)}>Vacaciones</button></li>
                                <li><button type="button" class="dropdown-item" onClick={() => handleAgregarIncidencia(usuario?._id)}>Incidencia o falta</button></li>
                                <li><button type="button" class="dropdown-item" onClick={() => handleAgregarRemoto(usuario?._id)}>Sesión remota</button></li>
                            </ul>
                        </div>
                    </div>

                
                    <Calendar
                        components={{
                            event: CalendarEvent
                        }}
                        culture="es"
                        endAccessor="end"
                        eventPropGetter={eventStyleGetter}
                        events={eventos} 
                        localizer={localizer}
                        messages={messages}
                        onSelectEvent={onSelectEvent}
                        onView={onViewChange}
                        onRangeChange={onRangeChange}
                        startAccessor="start"
                        style={{ height: '100vh' }}
                        view={lastView}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default Calendario;