import { combineReducers } from 'redux';

import alert from './alert';
import loader from './loader';
import breadcrumb from './breadcrumb';

import auth from './auth';
import dashboard from './dashboard';
import colaboradores from './colaboradores';
import tipos_colaboradores from './tipos_colaboradores';
import empresas from './empresas';
import calendario from './calendario';
import configuracion from './configuracion';

export default combineReducers({
    alert, loader, breadcrumb, auth, dashboard, colaboradores, tipos_colaboradores, empresas, calendario, configuracion
});