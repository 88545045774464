import { EMPRESAS } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null, 
    detalles_archivo: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case EMPRESAS.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case EMPRESAS.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case EMPRESAS.ELIMINAR:
            return {
                ...state,
                listado: state.listado.filter( empresa => empresa._id !== payload.id )
            };

        case EMPRESAS.ARCHIVOS.LISTAR:
            return {
                detalles: {
                    ...state.detalles,
                    informacion_general: {
                        ...state.detalles.informacion_general,
                        archivos: [
                            ...payload.archivos
                        ]
                    }
                }
            };

        case EMPRESAS.ARCHIVOS.DETALLES:
            return {
                ...state,
                detalles_archivo: payload
            };

        default:
            return state;
    }
}