import axios from 'axios';

import { CALENDARIO } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const buscarEventos = (fecha_inicial, fecha_final) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        fecha_inicial, 
        fecha_final
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/calendario/listar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CALENDARIO.BUSCAR,
            payload: res.data.eventos
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const informacionEvento = (id) => async dispatch => {
    if(id){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body = {
            id
        };

        try{
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/calendario/detalles`, body, config);

            await dispatch({
                type: CALENDARIO.DETALLES,
                payload: res.data.evento
            });
        } catch (error){         
            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        dispatch({
            type: CALENDARIO.DETALLES,
            payload: null
        });
    }
}

export const limpiarEvento = () => dispatch => {
    dispatch({
        type: CALENDARIO.DETALLES,
        payload: null
    });
}