import React, { Fragment, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Components
import Alert from './components/layout/Alert';
import Layout from './components/layout/Layout';
import PrivateRoute from './components/routing/PrivateRoute';
import ValidateSession from './components/routing/ValidateSession';
import NoMatch from './components/routing/NoMatch';

// Login
import Login from './views/auth/Login';

// Dashboard
import Dashboard from './views/dashboard/Dashboard';

// Colaboradores
import Colaboradores from './views/colaboradores/colaboradores/Colaboradores';
import NuevoColaborador from './views/colaboradores/colaboradores/NuevoColaborador';
import EditarColaborador from './views/colaboradores/colaboradores/EditarColaborador';
import PerfilColaborador from './views/colaboradores/colaboradores/PerfilColaborador';

import TiposColaboradores from './views/colaboradores/tipos/Tipos';
import NuevoTipoColaborador from './views/colaboradores/tipos/NuevoTipo';
import EditarTipoColaborador from './views/colaboradores/tipos/EditarTipo';

// Empresas
import Empresas from './views/empresas/Empresas';
import NuevaEmpresa from './views/empresas/NuevaEmpresa';
import EditarEmpresa from './views/empresas/EditarEmpresa';
import PerfilEmpresa from './views/empresas/PerfilEmpresa';

// Inventario
import Inventario from './views/inventario/Inventario';

// Calendario
import Calendario from './views/calendario/Calendario';

// Reportes
import Reportes from './views/reportes/Reportes';

// Configuración
import Productos from './views/configuracion/productos/Productos';
import Servicios from './views/configuracion/servicios/Servicios';
import Perfil from './views/perfil/Perfil';

// Redux
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

function App() {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	useEffect(() => {
		store.dispatch(loadUser());
	}, []);

	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Layout></Layout>
					<section className="container-fluid principal-section">
						<Alert></Alert>

						<Routes>
							{/* Login */}
							<Route exact path="/" element={<ValidateSession><Login /></ValidateSession>}/>
							<Route exact path="/login" element={<ValidateSession><Login /></ValidateSession>}/>

							{/* Dashboard */}
							<Route path="/dashboard" element={<PrivateRoute routePermission=''><Dashboard /></PrivateRoute>}/>

							{/* Colaboradores */}
							<Route path="/colaboradores/colaboradores" element={<PrivateRoute routePermission='colaboradores.ver'><Colaboradores /></PrivateRoute>}/>
							<Route path="/colaboradores/colaboradores/nuevo" element={<PrivateRoute routePermission='colaboradores.agregar'><NuevoColaborador /></PrivateRoute>}/>
							<Route path="/colaboradores/colaboradores/editar/:id" element={<PrivateRoute routePermission='colaboradores.editar'><EditarColaborador /></PrivateRoute>}/>
							<Route path="/colaboradores/colaboradores/perfil/:id" element={<PrivateRoute routePermission='colaboradores.perfil'><PerfilColaborador /></PrivateRoute>}/>

							<Route path="/colaboradores/tipos" element={<PrivateRoute routePermission='tipos_colaboradores.ver'><TiposColaboradores /></PrivateRoute>}/>
							<Route path="/colaboradores/tipos/nuevo" element={<PrivateRoute routePermission='tipos_colaboradores.agregar'><NuevoTipoColaborador /></PrivateRoute>}/>
							<Route path="/colaboradores/tipos/editar/:id" element={<PrivateRoute routePermission='tipos_colaboradores.editar'><EditarTipoColaborador /></PrivateRoute>}/>

							{/* Ingresos */}
							<Route path="/ingresos/punto" element={<PrivateRoute routePermission='punto_de_venta.ver'><Productos /></PrivateRoute>}/>

							<Route path="/ingresos/cotizador" element={<PrivateRoute routePermission='cotizador.ver'><Productos /></PrivateRoute>}/>

							<Route path="/ingresos/clientes" element={<PrivateRoute routePermission='clientes.ver'><Empresas /></PrivateRoute>}/>
							<Route path="/ingresos/clientes/nueva" element={<PrivateRoute routePermission='clientes.agregar'><NuevaEmpresa /></PrivateRoute>}/>
							<Route path="/ingresos/clientes/editar/:id" element={<PrivateRoute routePermission='clientes.editar'><EditarEmpresa /></PrivateRoute>}/>
							<Route path="/ingresos/clientes/perfil/:id" element={<PrivateRoute routePermission='clientes.perfil'><PerfilEmpresa /></PrivateRoute>}/>

							{/* Egresos */}
							<Route path="/egresos/ordenes" element={<PrivateRoute routePermission='ordenes.ver'><Empresas /></PrivateRoute>}/>
							<Route path="/egresos/ordenes/nueva" element={<PrivateRoute routePermission='ordenes.agregar'><NuevaEmpresa /></PrivateRoute>}/>
							<Route path="/egresos/ordenes/editar/:id" element={<PrivateRoute routePermission='ordenes.editar'><EditarEmpresa /></PrivateRoute>}/>
							<Route path="/egresos/ordenes/detalles/:id" element={<PrivateRoute routePermission='ordenes.detalles'><PerfilEmpresa /></PrivateRoute>}/>

							<Route path="/egresos/gastos" element={<PrivateRoute routePermission='gastos.ver'><Empresas /></PrivateRoute>}/>
							<Route path="/egresos/gastos/nueva" element={<PrivateRoute routePermission='gastos.agregar'><NuevaEmpresa /></PrivateRoute>}/>
							<Route path="/egresos/gastos/editar/:id" element={<PrivateRoute routePermission='gastos.editar'><EditarEmpresa /></PrivateRoute>}/>
							<Route path="/egresos/gastos/detalles/:id" element={<PrivateRoute routePermission='gastos.detalles'><PerfilEmpresa /></PrivateRoute>}/>

							<Route path="/egresos/proveedores" element={<PrivateRoute routePermission='proveedores.ver'><Empresas /></PrivateRoute>}/>
							<Route path="/egresos/proveedores/nueva" element={<PrivateRoute routePermission='proveedores.agregar'><NuevaEmpresa /></PrivateRoute>}/>
							<Route path="/egresos/proveedores/editar/:id" element={<PrivateRoute routePermission='proveedores.editar'><EditarEmpresa /></PrivateRoute>}/>
							<Route path="/egresos/proveedores/perfil/:id" element={<PrivateRoute routePermission='proveedores.perfil'><PerfilEmpresa /></PrivateRoute>}/>

							{/* Inventario */}
							<Route path="/inventario" element={<PrivateRoute routePermission='inventario.ver'><Inventario /></PrivateRoute>}/>
							
							{/* Calendario */}
							<Route path="/calendario" element={<PrivateRoute routePermission='calendario.ver'><Calendario /></PrivateRoute>}/>

							{/* Reportes */}
							<Route path="/reportes" element={<PrivateRoute routePermission='reportes.ver'><Reportes /></PrivateRoute>}/>

							{/* Perfil */}
							<Route path="/perfil" element={<PrivateRoute routePermission='perfil.ver'><Perfil /></PrivateRoute>}/>

							{/* Configuración */}
							<Route path="/configuracion/productos" element={<PrivateRoute routePermission='productos.ver'><Productos /></PrivateRoute>}/>
							<Route path="/configuracion/servicios" element={<PrivateRoute routePermission='servicios.ver'><Servicios /></PrivateRoute>}/>
							<Route path="/configuracion/unidades" element={<PrivateRoute routePermission='unidades.ver'><Servicios /></PrivateRoute>}/>
							<Route path="/configuracion/categorias" element={<PrivateRoute routePermission='categorias.ver'><Servicios /></PrivateRoute>}/>
							<Route path="/configuracion/ubicaciones" element={<PrivateRoute routePermission='ubicaciones.ver'><Servicios /></PrivateRoute>}/>
							
							{/* Cuando no matchea con nada*/}
							<Route path="*" element={<NoMatch />}/>
						</Routes>
					</section>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;