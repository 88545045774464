import React, { useEffect, Fragment } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Badge } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatNumber } from '../../../utils/generalFunctions';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { setAlert } from '../../../actions/alert';
import { obtenerColaborador, limpiarColaborador, detallesArchivo, limpiarArchivo } from '../../../actions/colaboradores';

import StatusColaborador from './StatusColaborador';
import EditarContratacion from './EditarContratacion';
import EliminarContratacion from './EliminarContratacion';
import NuevaVacacion from './NuevaVacacion';
import EditarVacacion from './EditarVacacion';
import StatusVacacion from './StatusVacacion';
import EliminarVacacion from './EliminarVacacion';
import NuevaIncidencia from './NuevaIncidencia';
import EditarIncidencia from './EditarIncidencia';
import StatusIncidencia from './StatusIncidencia';
import EliminarIncidencia from './EliminarIncidencia';
import NuevoRemoto from './NuevoRemoto';
import EditarRemoto from './EditarRemoto';
import StatusRemoto from './StatusRemoto';
import EliminarRemoto from './EliminarRemoto';
import NuevoReporte from './NuevoReporte';
import EditarReporte from './EditarReporte';
import EliminarReporte from './EliminarReporte';
import NuevoArchivo from './NuevoArchivo';
import EditarArchivo from './EditarArchivo';
import EliminarArchivo from './EliminarArchivo';

const PerfilColaborador = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Colaboradores',
            path: '/colaboradores/colaboradores'
        },
        {
            activo: true,
            nombre: 'Perfil del colaborador',
            path: '/colaboradores/colaboradores/perfil/'
        }
    ];

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles, detalles_archivo } = useSelector(state => state.colaboradores);

    const [colaboradorData, setColaboradorData] = useState({
        informacion_personal: {
            id: null,
            nombre: null,
            apellido: null,
            nacimiento: null,
            email: null,
            telefono: null,
            telefono_casa : null,
            direccion : null,
            curp: null, 
            rfc: null, 
            banco: null, 
            numero_cuenta: null, 
            clabe_interbancaria: null, 
            tipo_sangre: null,
            seguro_externo: false, 
            compania_seguro: null, 
            poliza_seguro: null, 
            contactos: [], 
            anotaciones: [], 
            pasatiempos : null, 
            status: 0, 
            url_perfil: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
        },
        informacion_profesional: {
            tipo_contrato: null,
            puesto: null,
            jefe_directo: null,
            email_laboral : null,
            telefono_laboral: null,
            sueldo_mensual: null,
            tipo_sueldo: null,
            dias_vacaciones: null,
            liga_calendario: null,
            prestaciones: [],
            contrataciones: [], 
            vacaciones: [], 
            incidencias: [], 
            reportes: [], 
            archivos: []
        }
    });

    const [colaboradorStatus, setColaboradorStatus] = useState({
        colaborador: null, 
        status: false, 
        contrataciones: 0
    });

    /* Contrataciones */
    const [editarContratacion, setEditarContratacion] = useState(null);
    const [eliminarContratacion, setEliminarContratacion] = useState(null);

    /* Vacaciones */
    const [nuevaVacacion, setNuevaVacacion] = useState({
        colaborador: null
    });
    const [editarVacacion, setEditarVacacion] = useState(null);
    const [statusVacacion, setStatusVacacion] = useState(null);    
    const [eliminarVacacion, setEliminarVacacion] = useState(null);

    /* Incidencias */
    const [nuevaIncidencia, setNuevaIncidencia] = useState({
        colaborador: null
    });
    const [editarIncidencia, setEditarIncidencia] = useState(null);
    const [statusIncidencia, setStatusIncidencia] = useState(null);    
    const [eliminarIncidencia, setEliminarIncidencia] = useState(null);

    /* Remotos */
    const [nuevoRemoto, setNuevoRemoto] = useState({
        colaborador: null
    });
    const [editarRemoto, setEditarRemoto] = useState(null);
    const [statusRemoto, setStatusRemoto] = useState(null);    
    const [eliminarRemoto, setEliminarRemoto] = useState(null);

    /* Reportes */
    const [nuevoReporte, setNuevoReporte] = useState({
        colaborador: null
    });
    const [editarReporte, setEditarReporte] = useState(null);
    const [eliminarReporte, setEliminarReporte] = useState(null);

    /* Archivos */
    const [nuevoArchivo, setNuevoArchivo] = useState({
        colaborador: null
    });
    const [editarArchivo, setEditarArchivo] = useState(null);
    const [eliminarArchivo, setEliminarArchivo] = useState(null);

    const contratacionesColumnas = [
        {
            name: 'Fecha de inicio',
            cell: item => item.inicio && item.inicio != '0000-00-00' ? new Date(item.inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'
        },
        {
            name: 'Fecha de finalización',
            cell: item => item.fin && item.fin != '0000-00-00' ? new Date(item.fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'
        },
        {
            name: 'Observaciones de ingreso',
            selector: row => row.observaciones_inicio || 'Sin definir'
        },
        {
            name: 'Observaciones de salida',
            selector: row => row.observaciones_fin || 'Sin definir'
        },
        {
            name: 'Status',
            selector: (row, index) => {
                switch(row.status){
                    case true:
                        return(
                            <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleStatusColaborador(id, index)}><Badge pill bg="success">Activa</Badge></button></h5>
                        )
                    case false:
                        return(
                            <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleStatusColaborador(id, index)}><Badge pill bg="danger">Finalizada</Badge></button></h5>
                        )
                    default:
                        break;
                }
            }
        }, 
        {
            name: '',
            grow: 1, 
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarContratacion({...row, id_colaborador: id})}><i className="fas fa-times text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarContratacion({...row, id_colaborador: id, status_colaborador: informacion_personal?.status })}><i className="fas fa-edit text-primary"></i></button>
                    </div>
                )
            }
        }
    ];

    const vacacionesColumnas = [
        {
            name: 'Dias solicitados',
            wrap: true,
            grow: 2, 
            selector: row => convertPeriodosTexto(row.periodos)
        },
        {
            name: 'Cantidad de dias',
            selector: row => convertPeriodosDias(row.periodos)
        },
        {
            name: 'Responsable',
            selector: row => row.responsable ? [row.responsable.nombre, row.responsable.apellido].join(' ') : 'Sin definir'
        },
        {
            name: 'Observaciones',
            grow: 2, 
            selector: row => row.observaciones || 'Sin definir'
        },
        {
            name: 'Status',
            selector: row => {
                switch(row.status){
                    case 1:
                    case '1':
                        return(
                            <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleStatusVacacion({...row, id_colaborador: id})}><Badge pill bg="warning">Pendiente</Badge></button></h5>
                        )
                    case 2:
                    case '2':
                        return(
                            <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleStatusVacacion({...row, id_colaborador: id})}><Badge pill bg="success">Autorizada</Badge></button></h5>
                        )
                    case 3:
                    case '3':
                        return(
                            <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleStatusVacacion({...row, id_colaborador: id})}><Badge pill bg="danger" >Rechazada</Badge></button></h5>
                        )
                    default:
                        break;
                }
            }
        }, 
        {
            name: '',
            grow: 1, 
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarVacacion({...row, id_colaborador: id})}><i className="fas fa-times text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarVacacion({...row, id_colaborador: id})}><i className="fas fa-edit text-primary"></i></button>
                    </div>
                )
            }
        }
    ];

    const incidenciasColumnas = [
        {
            name: 'Dias contemplados',
            wrap: true,
            grow: 2, 
            selector: row => convertPeriodosTexto(row.periodos)
        },
        {
            name: 'Cantidad de dias',
            selector: row => convertPeriodosDias(row.periodos)
        },
        {
            name: 'Responsable',
            selector: row => row.responsable ? [row.responsable.nombre, row.responsable.apellido].join(' ') : 'Sin definir'
        },
        {
            name: 'Observaciones',
            grow: 2, 
            selector: row => row.observaciones || 'Sin definir'
        },
        {
            name: 'Status',
            selector: row => {
                switch(row.status){
                    case 1:
                    case '1':
                        return(
                            <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleStatusIncidencia({...row, id_colaborador: id})}><Badge pill bg="warning">Pendiente</Badge></button></h5>
                        )
                    case 2:
                    case '2':
                        return(
                            <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleStatusIncidencia({...row, id_colaborador: id})}><Badge pill bg="success">Autorizada</Badge></button></h5>
                        )
                    case 3:
                    case '3':
                        return(
                            <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleStatusIncidencia({...row, id_colaborador: id})}><Badge pill bg="danger" >Rechazada</Badge></button></h5>
                        )
                    default:
                        break;
                }
            }
        }, 
        {
            name: '',
            grow: 1, 
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarIncidencia({...row, id_colaborador: id})}><i className="fas fa-times text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarIncidencia({...row, id_colaborador: id})}><i className="fas fa-edit text-primary"></i></button>
                    </div>
                )
            }
        }
    ];

    const remotosColumnas = [
        {
            name: 'Dias contemplados',
            wrap: true,
            grow: 2, 
            selector: row => convertPeriodosTexto(row.periodos)
        },
        {
            name: 'Cantidad de dias',
            selector: row => convertPeriodosDias(row.periodos)
        },
        {
            name: 'Responsable',
            selector: row => row.responsable ? [row.responsable.nombre, row.responsable.apellido].join(' ') : 'Sin definir'
        },
        {
            name: 'Observaciones',
            grow: 2, 
            selector: row => row.observaciones || 'Sin definir'
        },
        {
            name: 'Status',
            selector: row => {
                switch(row.status){
                    case 1:
                    case '1':
                        return(
                            <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleStatusRemoto({...row, id_colaborador: id})}><Badge pill bg="warning">Pendiente</Badge></button></h5>
                        )
                    case 2:
                    case '2':
                        return(
                            <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleStatusRemoto({...row, id_colaborador: id})}><Badge pill bg="success">Autorizada</Badge></button></h5>
                        )
                    case 3:
                    case '3':
                        return(
                            <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleStatusRemoto({...row, id_colaborador: id})}><Badge pill bg="danger" >Rechazada</Badge></button></h5>
                        )
                    default:
                        break;
                }
            }
        }, 
        {
            name: '',
            grow: 1, 
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarRemoto({...row, id_colaborador: id})}><i className="fas fa-times text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarRemoto({...row, id_colaborador: id})}><i className="fas fa-edit text-primary"></i></button>
                    </div>
                )
            }
        }
    ];

    const reportesColumnas = [
        {
            name: 'Dias contemplados',
            wrap: true,
            grow: 2, 
            selector: row => convertPeriodosTexto(row.periodos)
        },
        {
            name: 'Responsable',
            selector: row => row.responsable ? [row.responsable.nombre, row.responsable.apellido].join(' ') : 'Sin definir'
        },
        {
            name: 'Observaciones',
            grow: 2, 
            selector: row => row.observaciones || 'Sin definir'
        },
        {
            name: '',
            grow: 1, 
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarReporte({...row, id_colaborador: id})}><i className="fas fa-times text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarReporte({...row, id_colaborador: id})}><i className="fas fa-edit text-primary"></i></button>
                    </div>
                )
            }
        }
    ];

    const archivosColumnas = [
        {
            name: 'Nombre',
            grow: 2, 
            selector: row => row.nombre || 'Sin definir'
        },
        {
            name: 'Descripción',
            grow: 2, 
            selector: row => row.descripcion || 'Sin definir'
        },
        {
            name: 'Tipo',
            selector: row => row.extension ? row.extension.toUpperCase() : 'Sin definir'
        },
        {
            name: 'Fecha de carga',
            cell: item => item.fecha && item.fecha != '0000-00-00' ? new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'
        },
        {
            name: '',
            grow: 1, 
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarArchivo({ id_colaborador: id, id_archivo: row.id, nombre: row.nombre })}><i className="fas fa-times text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarArchivo({ id_colaborador: id, id_archivo: row.id, ...row })}><i className="fas fa-edit text-primary"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDetallesArchivo({ id_colaborador: id, id_archivo: row.id })}><i className="fas fa-eye text-info"></i></button>
                    </div>
                )
            }
        }
    ];

    const {informacion_personal, informacion_profesional} = colaboradorData;

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    /* Contrataciones */

    const handleEditarContratacion = (contratacion) => {
        setEditarContratacion(contratacion);
    }

    const handleEliminarContratacion = (contratacion) => {
        setEliminarContratacion(contratacion);
    }

    /* Vacaciones */

    const handleAgregarVacacion = (colaborador) => {
        setNuevaVacacion({
            colaborador: colaborador
        });
    }

    const handleEditarVacacion = (vacacion) => {
        setEditarVacacion(vacacion);
    }

    const handleStatusVacacion = (vacacion) => {
        setStatusVacacion(vacacion);
    }

    const handleEliminarVacacion = (vacacion) => {
        setEliminarVacacion(vacacion);
    }

    /* Incidencia */

    const handleAgregarIncidencia = (colaborador) => {
        setNuevaIncidencia({
            colaborador: colaborador
        });
    }

    const handleEditarIncidencia = (incidencia) => {
        setEditarIncidencia(incidencia);
    }

    const handleStatusIncidencia = (incidencia) => {
        setStatusIncidencia(incidencia);
    }

    const handleEliminarIncidencia = (incidencia) => {
        setEliminarIncidencia(incidencia);
    }

    /* Remoto */

    const handleAgregarRemoto = (colaborador) => {
        setNuevoRemoto({
            colaborador: colaborador
        });
    }

    const handleEditarRemoto = (remoto) => {
        setEditarRemoto(remoto);
    }

    const handleStatusRemoto = (remoto) => {
        setStatusRemoto(remoto);
    }

    const handleEliminarRemoto = (remoto) => {
        setEliminarRemoto(remoto);
    }

    /* Reportes */

    const handleAgregarReporte = (colaborador) => {
        setNuevoReporte({
            colaborador: colaborador
        });
    }

    const handleEditarReporte = (reporte) => {
        setEditarReporte(reporte);
    }

    const handleEliminarReporte = (reporte) => {
        setEliminarReporte(reporte);
    }

    /* Archivos */
    const handleAgregarArchivo = (colaborador) => {
        setNuevoArchivo({
            colaborador
        });
    }

    const handleDetallesArchivo = (archivo) => {
        dispatch(detallesArchivo(archivo));
    }

    const handleAbrirArchivo = () => {
        if(!editarArchivo){
            window.open(detalles_archivo.url);
        }
    }

    const handleEditarArchivo = (colaborador) => {
        setEditarArchivo(colaborador);
    }

    const handleEliminarArchivo = (colaborador) => {
        setEliminarArchivo(colaborador);
    }

    /* Funciones generales */
    const handleStatusColaborador = (colaborador, contratacion) => {
        if(contratacion == 0){
            setColaboradorStatus({
                colaborador: colaborador, 
                status: informacion_personal.status, 
                contrataciones: informacion_profesional.contrataciones.length
            });
        }else{
            dispatch(setAlert('Solo la ultima contratación puede ser modificada', 'danger'));
        }
    }

    const convertFechaTiempo = (fecha) => {
        let mensaje = 'Sin definir';

        if(fecha && fecha != '0000-00-00'){
            let diferencia = Math.floor(new Date().getTime() - new Date(fecha).getTime());
            let cantidad_dia = 1000 * 60 * 60 * 24;

            let dias = Math.floor(diferencia / cantidad_dia);
            let meses = 0;
            let anos = 0;

            if(dias >= 365){
                anos = Math.floor(dias / 365);
                dias = dias - (anos * 365);
            }

            if(dias >= 30){
                meses = Math.floor(dias / 30);
                dias = dias - (meses * 30);
            }

            let texto_dias = dias + ' ' + (dias == 1 ? 'dia' : 'dias');
            let texto_meses = meses + ' ' + (meses == 1 ? 'mes' : 'meses');
            let texto_anos = anos + ' ' + (anos == 1 ? 'año' : 'años');

            mensaje = texto_anos + ' ' + texto_meses + ' ' + texto_dias;
        }

        return mensaje;
    }

    const convertPeriodosTexto = (periodos) => {
        let fechas_texto = [];
        let cantidad_dia = 1000 * 60 * 60 * 24;

        if(periodos.length > 0){
            for (var x = 0; x < periodos.length; x++) {
                let diferencia = Math.floor(new Date(periodos[x].fin).getTime() - new Date(periodos[x].inicio).getTime());

                if(Math.floor(diferencia / cantidad_dia) > 0){
                    fechas_texto.push(new Date(periodos[x].inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' al ' + new Date(periodos[x].fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }));
                }else{
                    fechas_texto.push(new Date(periodos[x].inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }));
                }
            }
        }

        return fechas_texto.length > 0 ? fechas_texto.join(', ') : 'Sin definir';
    }

    const convertPeriodosDias = (periodos) => {
        let dias = 0;
        let cantidad_dia = 1000 * 60 * 60 * 24;

        if(periodos.length > 0){
            for (var x = 0; x < periodos.length; x++) {
                let diferencia = Math.floor(new Date(periodos[x].fin).getTime() - new Date(periodos[x].inicio).getTime());
                dias += Math.floor(diferencia / cantidad_dia) + 1;
            }
        }

        return dias;
    }

    const convertRegistrosDias = (registros) => {
        let dias = 0;
        let cantidad_dia = 1000 * 60 * 60 * 24;
        let ano_actual = new Date().getFullYear();
        let primer_dia = new Date(ano_actual, 0, 1);
        let final_dia = new Date((ano_actual + 1), 0, 1);

        if(registros.length > 0){
            for (var i = 0; i < registros.length; i++) {
                if(registros[i].periodos.length > 0){
                    for (var x = 0; x < registros[i].periodos.length; x++) {
                        if(new Date(registros[i].periodos[x].inicio).getTime() >= primer_dia.getTime() && new Date(registros[i].periodos[x].fin).getTime() < final_dia.getTime()){
                            let diferencia = Math.floor(new Date(registros[i].periodos[x].fin).getTime() - new Date(registros[i].periodos[x].inicio).getTime());
                            dias += Math.floor(diferencia / cantidad_dia) + 1;
                        }else{
                            if(new Date(registros[i].periodos[x].inicio).getTime() >= primer_dia.getTime() && new Date(registros[i].periodos[x].inicio).getTime() < final_dia.getTime()){
                                let fecha_inicial = new Date(registros[i].periodos[x].inicio);

                                while(fecha_inicial.getTime() >= primer_dia.getTime() && fecha_inicial.getTime() < final_dia.getTime()){
                                    dias++;
                                    fecha_inicial.setDate(fecha_inicial.getDate() + 1);
                                }
                            }else{
                                let fecha_final = new Date(registros[i].periodos[x].fin);

                                while(fecha_final.getTime() >= primer_dia.getTime() && fecha_final.getTime() < final_dia.getTime()){
                                    dias++;
                                    fecha_final.setDate(fecha_final.getDate() - 1);
                                }
                            }
                        }
                    }
                }
            }
        }

        return dias;
    }

    const createFilasContactos = () => {

        let contactos_campos = [];

        if(informacion_personal.contactos?.length > 0){
            for (var x = 0; x < informacion_personal.contactos?.length; x++) {
                if(informacion_personal.contactos[x].nombre){
                    contactos_campos.push(
                        <div className="col-md-6 form-group" key={'contacto_' + x}>
                            <p><li><b className="text-dark">{informacion_personal.contactos[x].nombre} {informacion_personal.contactos[x].parentesco ? '(' + informacion_personal.contactos[x].parentesco + ')' : 'Sin definir'}</b>: {informacion_personal.contactos[x].telefono ? informacion_personal.contactos[x].telefono : 'Sin definir'}</li></p>
                        </div>
                    );
                }
            }
        }else{
            contactos_campos.push(
                <div className="col-md-12 form-group">
                    <p>Sin contactos de emergencia registrados.</p>
                </div>
            );
        }

        return contactos_campos;
    }

    const createFilasAnotaciones = () => {

        let anotaciones_campos = [];

        if(informacion_personal.anotaciones?.length > 0){
            for (var x = 0; x < informacion_personal.anotaciones?.length; x++) {
                if(informacion_personal.anotaciones[x].contenido){
                    anotaciones_campos.push(
                        <div className="col-md-4 form-group" key={'anotacion_' + x}>
                            <p><li>{informacion_personal.anotaciones[x].contenido || 'Sin definir'}</li></p>
                        </div>
                    );
                }
            }
        }else{
            anotaciones_campos.push(
                <div className="col-md-12 form-group">
                    <p>Sin notas o comentarios adicionales registrados.</p>
                </div>
            );
        }

        return anotaciones_campos;
    }

    useEffect(() => {
        dispatch(limpiarColaborador());
        dispatch(limpiarArchivo());

        return () => {
            dispatch(limpiarColaborador());
            dispatch(limpiarArchivo());
        }
    }, []);

    useEffect(() => {
        if(id){
            dispatch(obtenerColaborador(id));

            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    useEffect(() => {
        if(detalles){
            setColaboradorData({
                ...colaboradorData,
                informacion_personal: {
                    ...detalles.informacion_personal,
                    url_perfil: detalles.informacion_personal.url_perfil || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
                }, 
                informacion_profesional: {
                    ...detalles.informacion_profesional
                }
            });
        }
    }, [detalles]);

    useEffect(() => {        
        if(detalles_archivo && detalles_archivo?.extension && detalles_archivo?.url){
            handleAbrirArchivo();
        }
    }, [detalles_archivo]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .contenedor_imagen_perfil {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        #imagen_perfil {
                            width: auto;
                            height: 100%;
                            display: block;
                            position: absolute;
                            margin: auto;
                            top: -100%;
                            right: -100%;
                            bottom: -100%;
                            left: -100%;
                        }

                        .btn-badge-status{
                            background-color: #fff;
                            border: none;
                            outline: none;
                            padding: 0;
                        }

                        .valor_indicador {
                            font-size: 40px;
                            margin: 0;
                        }

                        .descripcion_indicador {
                            margin: 0;
                        }

                        .text-wrap {
                            white-space: normal;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <StatusColaborador colaborador={colaboradorStatus} reestablecerColaborador={handleStatusColaborador} />
            <EditarContratacion contratacion={editarContratacion} reestablecerContratacion={handleEditarContratacion} />
            <EliminarContratacion contratacion={eliminarContratacion} reestablecerContratacion={handleEliminarContratacion} />
            <NuevaVacacion vacacion={nuevaVacacion} reestablecerVacacion={handleAgregarVacacion} />
            <EditarVacacion vacacion={editarVacacion} reestablecerVacacion={handleEditarVacacion} />
            <StatusVacacion vacacion={statusVacacion} reestablecerVacacion={handleStatusVacacion} />
            <EliminarVacacion vacacion={eliminarVacacion} reestablecerVacacion={handleEliminarVacacion} />
            <NuevaIncidencia incidencia={nuevaIncidencia} reestablecerIncidencia={handleAgregarIncidencia} />
            <EditarIncidencia incidencia={editarIncidencia} reestablecerIncidencia={handleEditarIncidencia} />
            <StatusIncidencia incidencia={statusIncidencia} reestablecerIncidencia={handleStatusIncidencia} />
            <EliminarIncidencia incidencia={eliminarIncidencia} reestablecerIncidencia={handleEliminarIncidencia} />
            <NuevoRemoto remoto={nuevoRemoto} reestablecerRemoto={handleAgregarRemoto} />
            <EditarRemoto remoto={editarRemoto} reestablecerRemoto={handleEditarRemoto} />
            <StatusRemoto remoto={statusRemoto} reestablecerRemoto={handleStatusRemoto} />
            <EliminarRemoto remoto={eliminarRemoto} reestablecerRemoto={handleEliminarRemoto} />
            <NuevoReporte reporte={nuevoReporte} reestablecerReporte={handleAgregarReporte} />
            <EditarReporte reporte={editarReporte} reestablecerReporte={handleEditarReporte} />
            <EliminarReporte reporte={eliminarReporte} reestablecerReporte={handleEliminarReporte} />
            <NuevoArchivo archivo={nuevoArchivo} reestablecerArchivo={handleAgregarArchivo} />
            <EditarArchivo archivo={editarArchivo} reestablecerArchivo={handleEditarArchivo} />
            <EliminarArchivo archivo={eliminarArchivo} reestablecerArchivo={handleEliminarArchivo} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Perfil del colaborador</h3>
                    
                    <div className="row mb-4">
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ informacion_profesional?.reportes?.length }</p>
                            <p className="descripcion_indicador">Reportes registrados</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ informacion_profesional?.incidencias?.length }</p>
                            <p className="descripcion_indicador">Incidencias o permisos registrados</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ convertRegistrosDias(informacion_profesional?.vacaciones) }</p>
                            <p className="descripcion_indicador">Vacaciones tomadas</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ informacion_profesional?.dias_vacaciones ? informacion_profesional?.dias_vacaciones - convertRegistrosDias(informacion_profesional?.vacaciones) : 0 }</p>
                            <p className="descripcion_indicador">Vacaciones restantes</p>
                        </div>
                        <div className="col-md-4 text-center">
                            <p className="valor_indicador">{ informacion_personal?.status == 10 ? convertFechaTiempo(informacion_profesional?.contrataciones[0]?.inicio) : 'Sin definir' }</p>
                            <p className="descripcion_indicador">Tiempo trabajando</p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-3 py-3 text-center">
                            <label className="contenedor_imagen_perfil">
                                <div className="contenedor_imagen">
                                    <img id="imagen_perfil" src={ informacion_personal?.url_perfil }/>
                                </div>
                            </label>
                            <div className="mt-2 d-flex justify-content-center">
                                <button className="btn-badge-status" onClick={() => handleStatusColaborador(id, 0)}>
                                    <h3 className="text-muted">
                                        <Badge bg={informacion_personal?.status == 10 ? (informacion_profesional?.contrataciones?.length > 0 ? 'success' : 'warning') : 'danger'}>
                                            {informacion_personal?.status == 10 ? (informacion_profesional?.contrataciones?.length > 0 ? 'Activo' : 'Activo sin contrato') : 'Inactivo'}
                                        </Badge>
                                    </h3>
                                </button>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="accordion" id="accordionDetalles">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Información personal
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-3">
                                                <label className="fw-bold">Nombre</label>
                                                <p>{informacion_personal?.nombre || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Apellido</label>
                                                <p>{informacion_personal?.apellido || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Fecha de nacimiento</label>
                                                <p>{informacion_personal?.nacimiento && informacion_personal?.nacimiento != '0000-00-00' ? new Date(informacion_personal?.nacimiento).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Email</label>
                                                <p>{informacion_personal?.email || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="fw-bold">Teléfono</label>
                                                <p>{informacion_personal?.telefono || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="fw-bold">Teléfono casa</label>
                                                <p>{informacion_personal?.telefono_casa || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-5">
                                                <label className="fw-bold">Dirección</label>
                                                <p>{informacion_personal?.direccion || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">CURP</label>
                                                <p>{informacion_personal?.curp || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="fw-bold">RFC</label>
                                                <p>{informacion_personal?.rfc || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="fw-bold">Banco</label>
                                                <p>{informacion_personal?.banco || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="fw-bold">No. de cuenta</label>
                                                <p>{informacion_personal?.numero_cuenta || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">CLABE</label>
                                                <p>{informacion_personal?.clabe_interbancaria || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="fw-bold">Tipo de sangre</label>
                                                <p>{informacion_personal?.tipo_sangre || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="fw-bold">Seguro externo</label>
                                                <p>{informacion_personal?.seguro_externo ? 'Si' : 'No'}</p>
                                            </div>
                                            {
                                                informacion_personal?.seguro_externo ?
                                                    <>
                                                        <div className="col-md-2">
                                                            <label className="fw-bold">Compañia</label>
                                                            <p>{informacion_personal?.compania_seguro || 'Sin definir'}</p>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label className="fw-bold">Póliza</label>
                                                            <p>{informacion_personal?.poliza_seguro || 'Sin definir'}</p>
                                                        </div>
                                                    </>
                                                :
                                                    null
                                            }
                                            <div className="col-md-12">
                                                <label className="fw-bold">Hobbies</label>
                                                <p>{informacion_personal?.pasatiempos || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="fw-bold">Contactos de emergencia</label>
                                                <div className="row my-2">
                                                    { createFilasContactos() }
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="fw-bold">Anotaciones</label>
                                                <div className="row my-2">
                                                    { createFilasAnotaciones() }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            Información profesional
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-2">
                                                <label className="fw-bold">Usuario</label>
                                                <p>{informacion_profesional?.username || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="fw-bold">Tipo de contrato</label>
                                                <p>{informacion_profesional?.tipo_contrato || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Puesto</label>
                                                <p>{informacion_profesional?.puesto || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Jefe directo</label>
                                                <p>{informacion_profesional?.jefe_directo || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="fw-bold">Sueldo mensual</label>
                                                <p>{informacion_profesional?.sueldo_mensual ? formatNumber(informacion_profesional?.sueldo_mensual, 2, '$ ', '') + ' ' + (informacion_profesional?.tipo_sueldo || 'Sin definir') : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Email</label>
                                                <p>{informacion_profesional?.email_laboral || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="fw-bold">Teléfono</label>
                                                <p>{informacion_profesional?.telefono_laboral || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="fw-bold">Vacaciones al año</label>
                                                <p>{informacion_profesional?.dias_vacaciones || 'Sin definir'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                        Contrataciones
                                    </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <DataTable
                                                columns={contratacionesColumnas}
                                                data={informacion_profesional.contrataciones}
                                                pagination
                                                paginationPerPage={3}
                                                paginationRowsPerPageOptions={[3, 10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                            Vacaciones
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-12 text-end mb-3">
                                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                    <button type="button" className="btn btn-primary" onClick={() => handleAgregarVacacion(id)}><i className="fa-solid fa-plus"></i> Solicitud</button>
                                                </div>
                                            </div>
                                            <DataTable
                                                columns={vacacionesColumnas}
                                                data={informacion_profesional.vacaciones}
                                                pagination
                                                paginationPerPage={3}
                                                paginationRowsPerPageOptions={[3, 10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                            Incidencias o permisos
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-12 text-end mb-3">
                                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                    <button type="button" className="btn btn-primary" onClick={() => handleAgregarIncidencia(id)}><i className="fa-solid fa-plus"></i> Incidencia</button>
                                                </div>
                                            </div>
                                            <DataTable
                                                columns={incidenciasColumnas}
                                                data={informacion_profesional.incidencias}
                                                pagination
                                                paginationPerPage={3}
                                                paginationRowsPerPageOptions={[3, 10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSix">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                            Sesiones de trabajo remoto
                                        </button>
                                    </h2>
                                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-12 text-end mb-3">
                                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                    <button type="button" className="btn btn-primary" onClick={() => handleAgregarRemoto(id)}><i className="fa-solid fa-plus"></i> Sesión</button>
                                                </div>
                                            </div>
                                            <DataTable
                                                columns={remotosColumnas}
                                                data={informacion_profesional.remotos}
                                                pagination
                                                paginationPerPage={3}
                                                paginationRowsPerPageOptions={[3, 10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSeven">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                        Reportes
                                    </button>
                                    </h2>
                                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-12 text-end mb-3">
                                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                    <button type="button" className="btn btn-primary" onClick={() => handleAgregarReporte(id)}><i className="fa-solid fa-plus"></i> Reporte</button>
                                                </div>
                                            </div>
                                            <DataTable
                                                columns={reportesColumnas}
                                                data={informacion_profesional.reportes}
                                                pagination
                                                paginationPerPage={3}
                                                paginationRowsPerPageOptions={[3, 10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingEight">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                            Archivos
                                        </button>
                                    </h2>
                                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-12 text-end mb-3">
                                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                    <button type="button" className="btn btn-primary" onClick={() => handleAgregarArchivo(id)}><i className="fa-solid fa-plus"></i> Archivo</button>
                                                </div>
                                            </div>
                                            <DataTable
                                                columns={archivosColumnas}
                                                data={informacion_profesional.archivos}
                                                pagination
                                                paginationPerPage={3}
                                                paginationRowsPerPageOptions={[3, 10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PerfilColaborador;