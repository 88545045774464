import { CALENDARIO } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case CALENDARIO.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case CALENDARIO.AGREGAR:
            return {
                ...state,
                listado: [
                    ...state.listado, 
                    ...payload
                ]
            };

        case CALENDARIO.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case CALENDARIO.ELIMINAR:
            return {
                ...state,
                listado: state.listado.filter( evento => evento._id !== payload.id )
            };

        default:
            return state;
    }
}