import React, { useState, useEffect, createRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { crearEmpresa } from '../../actions/empresas';
//import { buscarCategoriasEmpresasConfiguracion, buscarRegimenesFiscalesConfiguracion } from '../../actions/configuracion';

import { CustomValueContainer } from '../../components/react_select/ReactSelect';

const NuevaEmpresa = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Empresas',
            path: '/empresas'
        },
        {
            activo: true,
            nombre: 'Nueva empresa',
            path: '/empresas/nueva'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { listado: listado_categorias_empresas } = useSelector(state => state.configuracion.servicios);
    const { listado: listado_regimenes_fiscales } = useSelector(state => state.configuracion.regimenes_fiscales);

    const [empresaData, setEmpresaData] = useState({
        imagen: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/imagen_placeholder.png', 
        nombre: null,
        categoria_empresa: null,
        director: null,
        direccion: null,
        fecha_creacion: null,

        rfc: null,
        razon_social: null,
        direccion_fiscal: null,
        representante_legal: null,
        regimen_fiscal: null, 
        correo_fiscal: null,
        telefono_fiscal: null,
        fecha_cif: null,

        cuentas_bancarias: []
    });

    const [imagenEmpresa, setImagenEmpresa] = useState({
        img: undefined
    });

    const [categoriaData, setCategoriaData] = useState(null);
    const [categoriasFilter, setCategoriasFilter] = useState([]);

    const [regimenFiscalData, setRegimenFiscalData] = useState(null);
    const [regimenesFilter, setRegimenesFilter] = useState([]);

    let img = createRef();
    
    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Nueva empresa */
    const handleChangeImg = () => {
        setImagenEmpresa({
            ...imagenEmpresa, 
            img: img.current.files[0]
        });
    }

    const handleInputChange = ({ target }) => {
        setEmpresaData({
            ...empresaData,
            [target.name]: target.value
        });
    }

    const handleSelectCategoria = (option) => {
        setCategoriaData(option);

        setEmpresaData({
            ...empresaData,
            categoria_empresa: option != null ? option.value : null
        });
    }

    const handleSelectRegimen = (option) => {
        setRegimenFiscalData(option);

        setEmpresaData({
            ...empresaData,
            regimen_fiscal: option != null ? option.value : null
        });
    }

    const handleDatePicker = (name, value) => {
        setEmpresaData({
            ...empresaData,
            [name]: value
        });
    }

    const handleGuardarEmpresa = async () => {
        if(empresaData.nombre){
            let formData = new FormData();

            formData.append('nombre', empresaData.nombre);
            formData.append('categoria_empresa', empresaData.categoria_empresa);
            formData.append('director', empresaData.director);
            formData.append('direccion', empresaData.direccion);
            formData.append('fecha_creacion', empresaData.fecha_creacion);
            formData.append('rfc', empresaData.rfc);
            formData.append('razon_social', empresaData.razon_social);
            formData.append('direccion_fiscal', empresaData.direccion_fiscal);
            formData.append('representante_legal', empresaData.representante_legal);
            formData.append('regimen_fiscal', empresaData.regimen_fiscal);
            formData.append('correo_fiscal', empresaData.correo_fiscal);
            formData.append('telefono_fiscal', empresaData.telefono_fiscal);
            formData.append('fecha_cif', empresaData.fecha_cif);
            formData.append('cuentas_bancarias', JSON.stringify(empresaData.cuentas_bancarias));
            formData.append('perfil', imagenEmpresa.img);

            await dispatch(crearEmpresa(formData));

            await navigate("/empresas");
        }else{
            await dispatch(setAlert('Todos los campos son obligatorios', 'danger'));
        }
    }

    /* Cuentas bancarias */
    const handleAgregarCuenta = () => {
        let cuentas_bancarias = empresaData.cuentas_bancarias;

        cuentas_bancarias.push({
            nombre: null, 
            banco: null,
            numero: null,
            clabe: null
        });

        setEmpresaData({ 
            ...empresaData, 
            cuentas_bancarias
        });
    }

    const handleCuentaChange = ({ target }) => {
        let nombre_separado = target.name.split('Cuenta_');

        let posicion = nombre_separado[1];
        let name = nombre_separado[0];
        
        let cuentas_bancarias = empresaData.cuentas_bancarias;

        cuentas_bancarias[posicion][name] = target.value || '';

        setEmpresaData({
            ...empresaData,
            cuentas_bancarias
        });
    }

    const handleFilasCuentasCampos = (cuentas_bancarias) => {

        let cuentas_bancarias_campos = [];

        if(cuentas_bancarias){
            if(cuentas_bancarias.length){
                for (let x = 0; x < cuentas_bancarias?.length; x++) {
                    cuentas_bancarias_campos.push(
                        <Fragment>
                            <div className="col-md-3 form-group mb-3">
                                <div className="input-group">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" placeholder="Nombre de cuenta" id={'nombreCuenta_' + x} name={'nombreCuenta_' + x} value={cuentas_bancarias[x].nombre} onChange={e => handleCuentaChange(e)}/>
                                        <label htmlFor="nombre_cuenta">Nombre de cuenta {x + 1}</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 form-group mb-3">
                                <div className="input-group">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" placeholder="Banco" id={'bancoCuenta_' + x} name={'bancoCuenta_' + x} value={cuentas_bancarias[x].banco} onChange={e => handleCuentaChange(e)}/>
                                        <label htmlFor="banco">Banco {x + 1}</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 form-group mb-3">
                                <div className="input-group">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" placeholder="Numero de cuenta" id={'numeroCuenta_' + x} name={'numeroCuenta_' + x} value={cuentas_bancarias[x].numero} onChange={e => handleCuentaChange(e)}/>
                                        <label htmlFor="numero_cuenta">Numero de cuenta {x + 1}</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 form-group mb-3">
                                <div className="input-group">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" placeholder="CLABE" id={'clabeCuenta_' + x} name={'clabeCuenta_' + x} value={cuentas_bancarias[x].clabe} onChange={e => handleCuentaChange(e)}/>
                                        <label htmlFor="clabe">CLABE {x + 1}</label>
                                    </div>

                                    <div className="input-group-append">
                                        <button className="btn btn-danger delete" id={'eliminarCuenta_' + x} onClick={e => handleEliminarCuenta(e)}><i className="fa-solid fa-xmark"></i></button>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    );
                }
            }else{
                cuentas_bancarias_campos.push(
                    <div className="col-md-12 my-3 text-center">
                        <p>No hay cuentas bancarias registradas hasta el momento.</p>
                    </div>
                );
            }
        }else{

        }

        return cuentas_bancarias_campos;
    }

    const handleEliminarCuenta = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let cuentas_bancarias = empresaData.cuentas_bancarias;

        cuentas_bancarias.splice(id_separado[1], 1);

        setEmpresaData({ 
            ...empresaData, 
            cuentas_bancarias
        });
    }

    useEffect(() => {
        /* dispatch(buscarCategoriasEmpresasConfiguracion());
        dispatch(buscarRegimenesFiscalesConfiguracion()); */
    }, []);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_categorias_empresas.length; i++) {
            options.push({ value: listado_categorias_empresas[i]._id, label: listado_categorias_empresas[i].nombre });
        }

        setCategoriasFilter(options);
    }, [listado_categorias_empresas]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_regimenes_fiscales.length; i++) {
            options.push({ value: listado_regimenes_fiscales[i]._id, label: listado_regimenes_fiscales[i].nombre });
        }

        setRegimenesFilter(options);
    }, [listado_regimenes_fiscales]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        input[type="file"] {
                            display: none;
                        }
                        
                        .custom-file-upload {
                            border-radius: 5%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            cursor: pointer;
                            background: #fff;
                            margin-bottom: 25px;
                            border: 5px solid #85ac11;
                        }
                        
                        .img-wrap{
                            position: relative;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 5%;
                        }

                        .img-upload:before{
                            content: "";
                            font-family: "Font Awesome 6 Free" !important;
                            font-weight: 900;
                            font-size: 90px;
                            position: absolute;
                            padding-top: 50px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            color: #caec67;
                            width: 100%;
                            height: 200px;
                            border-radius: 5%;
                            opacity: 0;
                            transition: .5s ease;
                            background-color: #fff;
                        }

                        .img-upload:hover:before{
                            opacity: 1;
                        }

                        #img-photo {
                            width: auto;
                            height: 100%;
                        }

                        .selectIconos, .select-group {
                            height: 100%;
                        }

                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Nueva empresa</h3>
                    
                    <div className="row mb-5">
                        <div className="col-12 form-group text-center">
                            <label htmlFor="photo-upload" className="custom-file-upload fas">
                                <div className="img-wrap img-upload">
                                    <img id="img-photo" htmlFor="photo-upload" src={imagenEmpresa.img != null ? URL.createObjectURL(imagenEmpresa.img) : empresaData.imagen}/>
                                </div>
                                <input id="photo-upload" type="file" accept="image/*" ref={img} onChange={handleChangeImg}/> 
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="accordion" id="accordionDetalles">
                                
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Información general
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                    <div className="accordion-body row">
                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={empresaData.nombre} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="nombre">Nombre</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="categoria_empresa"
                                                    name="categoria_empresa"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Categoría"
                                                    isClearable={true}
                                                    options={categoriasFilter}
                                                    value={categoriaData}
                                                    onChange={(value) => handleSelectCategoria(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Dirección" id="direccion" name="direccion" value={empresaData.direccion} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="direccion">Dirección</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <DatePicker
                                                        id="fecha_creacion"
                                                        name="fecha_creacion"
                                                        className="form-control text-center input-datepícker"
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selected={ empresaData.fecha_creacion != null ? new Date(empresaData.fecha_creacion) : '' }
                                                        onChange={ date => handleDatePicker('fecha_creacion', date) }
                                                    />
                                                    <label className="label-datepicker" htmlFor="fecha_creacion">Fundación</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                        Información fiscal
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                    <div className="accordion-body row">

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="RFC" id="rfc" name="rfc" value={empresaData.rfc} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="rfc">RFC</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Razón social" id="razon_social" name="razon_social" value={empresaData.razon_social} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="razon_social">Razón social</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Dirección fiscal" id="direccion_fiscal" name="direccion_fiscal" value={empresaData.direccion_fiscal} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="direccion_fiscal">Dirección fiscal</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Representante legal" id="representante_legal" name="representante_legal" value={empresaData.representante_legal} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="representante_legal">Representante legal</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="regimen_fiscal"
                                                    name="regimen_fiscal"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Régimen fiscal"
                                                    isClearable={true}
                                                    options={regimenesFilter}
                                                    value={regimenFiscalData}
                                                    onChange={(value) => handleSelectRegimen(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Correo" id="correo_fiscal" name="correo_fiscal" value={empresaData.correo_fiscal} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="correo_fiscal">Correo</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Teléfono" id="telefono_fiscal" name="telefono_fiscal" value={empresaData.telefono_fiscal} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="telefono_fiscal">Teléfono</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <DatePicker
                                                        id="fecha_cif"
                                                        name="fecha_cif"
                                                        className="form-control text-center input-datepícker"
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selected={ empresaData.fecha_cif != null ? new Date(empresaData.fecha_cif) : '' }
                                                        onChange={ date => handleDatePicker('fecha_cif', date) }
                                                    />
                                                    <label className="label-datepicker" htmlFor="fecha_cif">Fecha de cédula</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                        Información bancaria
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionDetalles">
                                    <div className="accordion-body row">
                                        <div className="col-md-12 text-end mb-3">
                                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                <button type="button" className="btn btn-primary" onClick={() => handleAgregarCuenta()}><i className="fa-solid fa-plus"></i> Cuenta bancaria</button>
                                            </div>
                                        </div>

                                        { handleFilasCuentasCampos(empresaData.cuentas_bancarias) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleGuardarEmpresa}>Guardar</button>
                            <Link to='/empresas'><button className="btn btn-danger float-end me-2">Cancelar</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NuevaEmpresa;