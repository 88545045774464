import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { setAlert } from '../../actions/alert';
import { crearRemoto } from '../../actions/colaboradores';

const NuevoRemoto = ({ remoto, reestablecerRemoto }) => {

    const dispatch = useDispatch();

    const [remotoData, setRemotoData] = useState({
        id: null, 
        periodos: [
            {
                inicio: new Date(), 
                fin: new Date()
            }
        ], 
        observaciones: null, 
        formato: 1
    });

    const [remotoModal, setRemotoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Agregar remoto */
    const handleInputChange = ({ target }) => {
        setRemotoData({
            ...remotoData,
            [target.name]: target.value
        });
    }

    const handleAgregarPeriodo = () => {
        let periodos = remotoData.periodos;

        periodos.push({
            inicio: new Date(), 
            fin: new Date()
        });

        setRemotoData({ 
            ...remotoData, 
            periodos
        });
    }

    const periodosCampos = (periodos) => {
        let periodos_campos = [];

        if(periodos.length > 0){
            for (let x = 0; x < periodos.length; x++) {
                periodos_campos.push(
                    <div className="col-md-6 form-group mb-3" key={'periodo_' + x}>
                        <div className="input-group h-100">
                            <div className="form-floating">
                                <DatePicker
                                    id={'fechas_' + x}
                                    name={'fechas_' + x}
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange={true}
                                    startDate={periodos[x].inicio}
                                    endDate={periodos[x].fin}
                                    autoComplete="off"
                                    onChange={ date => handleDatePicker('fechas_' + x, date) }
                                />
                                <label className="label-datepicker" htmlFor="fin">Rango de fechas</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-danger delete" id={'eliminarPeriodo_' + x} onClick={e => handleEliminarPeriodo(e)}><i className="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    </div>
                );
            }
        }else{
            periodos_campos.push(
                <div className="col-md-12 form-group my-3 text-center">
                    <p>Define al menos un periodo de fechas.</p>
                </div>
            );
        }

        return periodos_campos;
    }

    const handleDatePicker = (name, value) => {
        const [inicio, fin] = value;

        let id_separado = name.split('_');

        let periodos = remotoData.periodos;
        periodos[id_separado[1]].inicio = inicio;
        periodos[id_separado[1]].fin = fin;

        setRemotoData({
            ...remotoData,
            [name]: value
        });
    }

    const handleEliminarPeriodo = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let periodos = remotoData.periodos;

        periodos.splice(id_separado[1], 1);

        setRemotoData({ 
            ...remotoData, 
            periodos
        });
    }

    const handleProcesar = async () => {
        if(remotoData.periodos.length > 0){
            let interferencias = 0;

            for (let x = 0; x < remotoData.periodos.length; x++) {
                for (let i = 0; i < remotoData.periodos.length; i++) {
                    if(x !== i){
                        if((new Date(remotoData.periodos[x].inicio).getTime() >= new Date(remotoData.periodos[i].inicio).getTime() && new Date(remotoData.periodos[x].inicio).getTime() <= new Date(remotoData.periodos[i].fin).getTime()) || (new Date(remotoData.periodos[x].fin).getTime() >= new Date(remotoData.periodos[i].inicio).getTime() && new Date(remotoData.periodos[x].fin).getTime() <= new Date(remotoData.periodos[i].fin).getTime())){
                            interferencias++;
                        }
                    }
                }
            }

            if(interferencias == 0){
                await setStatusProcesar(true);
                await dispatch(crearRemoto(remotoData));
                await handleCerrar();
                await setStatusProcesar(false);
            }else{
                await dispatch(setAlert('Uno o mas periodos interfieren entre si', 'danger'));
            }
        }else{
            await dispatch(setAlert('El o los periodos de solicitudes de trabajo remoto son requeridos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setRemotoData({
                id: null, 
                periodos: [
                    {
                        inicio: new Date(), 
                        fin: new Date()
                    }
                ], 
                observaciones: null, 
                formato: 1
            });
            reestablecerRemoto(null);
            setRemotoModal(false);
        }
    }

    useEffect(() => {
        if(remoto.colaborador){
            setRemotoModal(true);

            setRemotoData({
                ...remotoData, 
                id: remoto.colaborador
            });
        }
    }, [remoto]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <Modal show={remotoModal} size="lg" backdrop="static" centered onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva sesión de trabajo remoto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        { periodosCampos(remotoData.periodos) }

                        <div className="col-md-12 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <textarea className="form-control textareCampo" placeholder="Observaciones" id="observaciones" name="observaciones" value={remotoData.observaciones} onChange={e => handleInputChange(e)}></textarea>
                                    <label htmlFor="observaciones">Observaciones</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                    {
                        statusProcesar ?
                        <button type="button" className="btn btn-success mr-3" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                        :
                        <button type="button" className="btn btn-success mr-3" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                    }
                    <button type="button" className="btn btn-primary" onClick={() => handleAgregarPeriodo()} disabled={ statusProcesar }><i className="fas fa-plus fa-sm"></i> Fecha</button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default NuevoRemoto;