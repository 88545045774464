import { CONFIGURACION_PRODUCTOS, CONFIGURACION_SERVICIOS, CONFIGURACION_REGIMENES_FISCALES } from '../actions/types';

const initialState = {
    productos: {
        listado: [],
        detalles: null
    }, 
    servicios: {
        listado: [],
        detalles: null
    },
    regimenes_fiscales: {
        listado: [],
        detalles: null
    }
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case CONFIGURACION_PRODUCTOS.BUSCAR:
            return {
                ...state,
                productos: {
                    ...state.productos, 
                    listado: payload
                }
            };

        case CONFIGURACION_PRODUCTOS.AGREGAR:
            let productos_agregar = state.productos.listado;

            productos_agregar = [
                ...productos_agregar, payload
            ];

            productos_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                productos: {
                    ...state.productos, 
                    listado: productos_agregar
                }
            };

        case CONFIGURACION_PRODUCTOS.DETALLES:
            return {
                ...state,
                productos: {
                    ...state.productos, 
                    detalles: payload
                }
            };

        case CONFIGURACION_PRODUCTOS.EDITAR:
            let productos_editar = state.productos.listado;

            productos_editar = productos_editar.map(producto => producto._id === payload._id ? payload : producto);

            productos_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                productos: {
                    ...state.productos, 
                    listado: productos_editar
                }
            };

        case CONFIGURACION_PRODUCTOS.ELIMINAR:
            return {
                ...state, 
                productos: {
                    ...state.productos, 
                    listado: state.productos.listado.filter( producto => producto._id !== payload.id )
                }
            };
    
        case CONFIGURACION_SERVICIOS.BUSCAR:
            return {
                ...state,
                servicios: {
                    ...state.servicios, 
                    listado: payload
                }
            };

        case CONFIGURACION_SERVICIOS.AGREGAR:
            let servicios_agregar = state.servicios.listado;

            servicios_agregar = [
                ...servicios_agregar, payload
            ];

            servicios_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                servicios: {
                    ...state.servicios, 
                    listado: servicios_agregar
                }
            };

        case CONFIGURACION_SERVICIOS.DETALLES:
            return {
                ...state,
                servicios: {
                    ...state.servicios, 
                    detalles: payload
                }
            };

        case CONFIGURACION_SERVICIOS.EDITAR:
            let servicios_editar = state.servicios.listado;

            servicios_editar = servicios_editar.map(categoria => categoria._id === payload._id ? payload : categoria);

            servicios_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                servicios: {
                    ...state.servicios, 
                    listado: servicios_editar
                }
            };

        case CONFIGURACION_SERVICIOS.ELIMINAR:
            return {
                ...state, 
                servicios: {
                    ...state.servicios, 
                    listado: state.servicios.listado.filter( categoria => categoria._id !== payload.id )
                }
            };
        
        case CONFIGURACION_REGIMENES_FISCALES.BUSCAR:
            return {
                ...state,
                regimenes_fiscales: {
                    ...state.regimenes_fiscales, 
                    listado: payload
                }
            };

        case CONFIGURACION_REGIMENES_FISCALES.AGREGAR:
            let regimenes_agregar = state.regimenes_fiscales.listado;

            regimenes_agregar = [
                ...regimenes_agregar, payload
            ];

            regimenes_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                regimenes_fiscales: {
                    ...state.regimenes_fiscales, 
                    listado: regimenes_agregar
                }
            };

        case CONFIGURACION_REGIMENES_FISCALES.DETALLES:
            return {
                ...state,
                regimenes_fiscales: {
                    ...state.regimenes_fiscales, 
                    detalles: payload
                }
            };

        case CONFIGURACION_REGIMENES_FISCALES.EDITAR:
            let regimenes_editar = state.regimenes_fiscales.listado;

            regimenes_editar = regimenes_editar.map(regimen => regimen._id === payload._id ? payload : regimen);

            regimenes_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                regimenes_fiscales: {
                    ...state.regimenes_fiscales, 
                    listado: regimenes_editar
                }
            };

        case CONFIGURACION_REGIMENES_FISCALES.ELIMINAR:
            return {
                ...state, 
                regimenes_fiscales: {
                    ...state.regimenes_fiscales, 
                    listado: state.regimenes_fiscales.listado.filter( regimen => regimen._id !== payload.id )
                }
            };
        
        default:
            return state;
    }
}