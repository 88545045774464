import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarProductosConfiguracion } from '../../../actions/configuracion';

import NuevoProducto from './NuevoProducto';
import EditarProducto from './EditarProducto';
import EliminarProducto from './EliminarProducto';

const Productos = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Productos',
            path: '/configuracion/productos'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.productos);

    const [productos, setProductos] = useState([]);

    const [productoAgregar, setProductoAgregar] = useState(null);
    const [productoEditar, setProductoEditar] = useState(null);
    const [productoEliminar, setProductoEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Descripción',
            selector: row => row.descripcion,
        },
        {
            name: '',
            cell: row => {
                if(row._id){
                    return(
                        <div className="text-right w-100">
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarProducto(row)}><i className="fas fa-trash text-danger"></i></button>
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarProducto(row)}><i className="fas fa-edit text-warning"></i></button>
                        </div>
                    )
                }else{
                    return(<></>)
                }
            }
        }
    ];

    /* Crear producto */
    const handleAgregarProducto = (producto) => {
        setProductoEditar(null);
        setProductoEliminar(null);

        setProductoAgregar(producto);        
    }

    /* Editar producto */
    const handleEditarProducto = (producto) => {
        setProductoAgregar(null);
        setProductoEliminar(null);

        setProductoEditar(producto);
    }

    /* Eliminar producto */
    const handleEliminarProducto = (producto) => {
        setProductoAgregar(null);
        setProductoEditar(null);

        setProductoEliminar(producto);
    }

    useEffect(() => {
        dispatch(buscarProductosConfiguracion());
    }, []);

    useEffect(() => {
        setProductos(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevoProducto producto={productoAgregar} reestablecerProducto={handleAgregarProducto} />
            <EditarProducto producto={productoEditar} reestablecerProducto={handleEditarProducto} />
            <EliminarProducto producto={productoEliminar} reestablecerProducto={handleEliminarProducto} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Productos</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarProducto({ })}>Agregar producto</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={productos}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Productos;